import cx from 'classnames'
import React from 'react'

import { TypographyVariant, TypographyFontWeight } from 'ui-elements/themes/types'

/**
 * Default mapping to element by variant
 */
const defaultVariantMapping: Partial<Record<TypographyVariant, string>> = {
  caption1: 'span',
  heading1: 'h1',
  'heading1-5': 'h1',
  heading2: 'h2',
}

export type TypographyColor =
  | 'currentColor'
  | 'text-primary'
  | 'text-secondary'
  | 'text-secondary-subdued'
  | 'text-accent'
  | 'text-accent-subdued'
  | 'text-subdued'
  | 'text-critical'
  | 'text-success'
  | 'text-disabled'
  | 'text-on-primary'
  | 'text-on-disabled'
  | 'red-300'
  | 'red-400'
  | 'red-500'
  | 'red-600'
  | 'red-800'
  | 'black-80'
  | 'black-60'
  | 'black-40'
  | 'grey-700'
  | 'grey-900'

/**
 * Typography component props
 */
export interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  as?: React.ElementType
  children?: React.ReactNode
  className?: string
  color?: TypographyColor
  display?: 'initial' | 'block' | 'inline' | 'inline-block'
  gutterTop?: number
  gutterBottom?: number
  variant?: TypographyVariant
  weight?: TypographyFontWeight
  textTransform?: React.CSSProperties['textTransform']
  textWrap?: React.CSSProperties['textWrap']
}

const DISPLAY: Record<string, any> = {
  block: 'block',
  inline: 'inline',
  'inline-block': 'inline-block',
}

const TEXT_TRANSFORM: Record<string, any> = {
  capitalize: 'capitalize',
  uppercase: 'uppercase',
  lowercase: 'lowercase',
}

const TEXT_ALIGN: Record<string, any> = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
  justify: 'text-justify',
}

const VARIANT: Record<TypographyVariant, string> = {
  heading1: 'text-heading1',
  'heading1-5': 'text-heading1-5',
  heading2: 'text-heading2',
  heading3: 'text-heading3',
  body1: 'text-body1',
  body1lg: 'text-body1lg',
  body2: 'text-body2',
  body3: 'text-body3',
  caption1: 'text-caption1',
  caption2: 'text-caption2',
  button1: 'text-button1',
  promo: 'text-promo',
}

const FONT_WEIGHT: Record<TypographyFontWeight, string> = {
  normal: 'font-normal',
  medium: 'font-medium',
  'semi-bold': 'font-semibold',
  heavy: 'font-extrabold',
}

/**
 * Renders Typography component that allows to use basic text styles
 */
const Typography = ({
  align = 'inherit',
  as,
  color = 'text-primary',
  display = 'initial',
  gutterTop = 0,
  gutterBottom = 0,
  variant = 'caption1',
  textTransform = 'none',
  textWrap,
  style: propStyle,
  weight,
  className,
  ...rest
}: TypographyProps): JSX.Element => {
  const Element = (as || defaultVariantMapping[variant]) ?? 'span'

  const style = React.useMemo(() => {
    const style: React.CSSProperties = {}

    if (gutterTop) {
      style.marginTop = gutterTop
    }

    if (gutterBottom) {
      style.marginBottom = gutterBottom
    }

    return style
  }, [gutterTop, gutterBottom])

  return (
    <Element
      className={cx(
        'm-0 font-default',
        `text-${color}`,
        textWrap && `text-${textWrap}`,
        VARIANT[variant] ?? '',
        DISPLAY[display] ?? '',
        TEXT_ALIGN[align] ?? '',
        TEXT_TRANSFORM[textTransform] ?? '',
        (weight && FONT_WEIGHT[weight]) ?? '',
        className
      )}
      style={{ ...style, ...propStyle }}
      {...rest}
    />
  )
}

export { Typography }
